import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import Cookie from 'universal-cookie'

library.add(fab, far, fas)

// export const onRouteUpdate = ({ location, prevLocation }) => {
// //    if (process.env.NODE_ENV !== `production` || typeof ga !== `function`) {
// //      return null
// //    }
  
// //    const pathIsExcluded =
// //      location &&
// //      typeof window.excludeGAPaths !== `undefined` &&
// //      window.excludeGAPaths.some(rx => rx.test(location.pathname))
// //  
// //    if (pathIsExcluded) return null
  
//     // wrap inside a timeout to make sure react-helmet is done with it's changes (https://github.com/gatsbyjs/gatsby/issues/9139)
//     // reactHelmet is using requestAnimationFrame: https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299
//       const sendPageView = () => {
//         const pagePath = location
//           ? location.pathname + location.search + location.hash
//           : undefined

//           let objConsent = typeof window !== 'undefined' && JSON.parse(window.localStorage.getItem("consent"))
//           if (objConsent.tracking == "true") {
      
//             window.ga(`set`, `page`, pagePath)
//             window.ga(`send`, `pageview`)
//           }
//         //console.log("old pathname", prevLocation ? prevLocation.pathname : null)
//         //console.log("new pathname", pagePath)
//       }

//       // Minimum delay for reactHelmet's requestAnimationFrame
//     const delay = Math.max(32, 100 || 0)
//     setTimeout(sendPageView, delay)
  
//     return null
//   }


const cookie = new Cookie()
const consent = cookie.get("CookieScriptConsent")
console.log(consent.categories)
    
// consent.addChangeListener(onCookieChange)

// function onCookieChange() {
//     console.log("Noticed cookie change!")
// }
